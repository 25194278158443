<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 43.8 56.9"
    style="enable-background:new 0 0 43.8 56.9;"
    xml:space="preserve"
  >
    <path
      :fill="color"
      d="M43.8,30.6V14.2c0-0.6-0.5-1.1-1.1-1.1H27.4c-0.6,0-1.1,0.5-1.1,1.1v16.4c0,4.5,3.3,8.1,7.7,8.7v15.4h-4.4
c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h10.9c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1h-4.4V39.3
C40.4,38.8,43.8,35.1,43.8,30.6z M28.5,15.3h13.1v10.9H28.5V15.3z M35,37.2c-3.6,0-6.6-2.9-6.6-6.6v-2.2h13.1v2.2
C41.6,34.3,38.6,37.2,35,37.2z M15.3,18.4V1.1c0-0.6-0.5-1.1-1.1-1.1H7.7C7.1,0,6.6,0.5,6.6,1.1v17.3c-4,1.7-6.6,5.7-6.6,10v25.2
c0,1.8,1.5,3.3,3.3,3.3h15.3c1.8,0,3.3-1.5,3.3-3.3V28.5C21.9,24.1,19.3,20.2,15.3,18.4z M8.8,2.2h4.4v4.4H8.8V2.2z M19.7,43.8H8.8
V32.8h10.9V43.8z M19.7,30.6h-12c-0.6,0-1.1,0.5-1.1,1.1v13.1c0,0.6,0.5,1.1,1.1,1.1h12v7.7c0,0.6-0.5,1.1-1.1,1.1H3.3
c-0.6,0-1.1-0.5-1.1-1.1V28.5c0-3.7,2.3-7,5.8-8.2c0.4-0.2,0.7-0.6,0.7-1V8.8h4.4v10.4c0,0.5,0.3,0.9,0.7,1c3.5,1.2,5.8,4.6,5.8,8.2
V30.6z"
    />
  </svg>
</template>
