<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len -->
  <!-- Generator: Adobe Illustrator 21.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 26.6 51.6"
    style="enable-background:new 0 0 26.6 51.6;"
    xml:space="preserve"
  >
    <path
      :fill="color"
      d="M26.6,14.5L9.5,0v22.5V23L0,15.1v2.4l9.5,8.3v0L0,34.1v2.4l9.5-7.9V29v22.5L26.6,37L13.3,25.8L26.6,14.5z M11.8,5L23,14.5
L11.8,24V5z M23,37l-11.2,9.5l0-19L23,37z"
    />
  </svg>
</template>
