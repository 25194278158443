<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 36 52"
    style="enable-background:new 0 0 36 52;"
    xml:space="preserve"
  >
    <path
      :fill="color"
      d="M35,4H22c-2.8,0-5,2.2-5,5v1h-2V9c0-3.9,3.1-7,7-7h13c0.6,0,1-0.4,1-1s-0.4-1-1-1H22c-5,0-9,4-9,9v1h-2
C4.9,10,0,14.9,0,21c0,0.6,0.4,1,1,1h30c0.6,0,1-0.4,1-1c0-6.1-4.9-11-11-11h-2V9c0-1.7,1.3-3,3-3h13c0.6,0,1-0.4,1-1S35.6,4,35,4z
 M21,12c4.6,0,8.4,3.5,8.9,8H2.1c0.5-4.5,4.3-8,8.9-8H21z M7,44c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-6
C8,44.4,7.6,44,7,44z M13,44c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-6C14,44.4,13.6,44,13,44z M19,44
c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-6C20,44.4,19.6,44,19,44z M25,44c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1
c0.6,0,1-0.4,1-1v-6C26,44.4,25.6,44,25,44z M7,34c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-6C8,34.4,7.6,34,7,34z
 M13,34c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-6C14,34.4,13.6,34,13,34z M19,34c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1
c0.6,0,1-0.4,1-1v-6C20,34.4,19.6,34,19,34z M25,34c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-6C26,34.4,25.6,34,25,34z
 M7,24c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-6C8,24.4,7.6,24,7,24z M13,24c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1
c0.6,0,1-0.4,1-1v-6C14,24.4,13.6,24,13,24z M19,24c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-6C20,24.4,19.6,24,19,24z
 M25,24c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-6C26,24.4,25.6,24,25,24z"
    />
  </svg>
</template>
