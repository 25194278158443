<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    role="presentation"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 55.6 52"
    style="enable-background:new 0 0 55.6 52;"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          :fill="color"
          d="M50.5,33.1c0.2,0.4,0.8,0.4,1,0l4-7.3c0.2-0.4-0.1-0.9-0.5-0.9h-2.5c-0.3,0-0.5-0.2-0.5-0.5C51.2,10.9,39.8,0,26,0
C11.7,0,0,11.7,0,26s11.7,26,26,26c8.9,0,17.2-4.6,22-12.1c0.3-0.5,0.2-1.1-0.3-1.4c-0.5-0.3-1.1-0.1-1.4,0.4
C41.9,45.8,34.2,50,26,50C12.8,50,2,39.2,2,26S12.8,2,26,2c12.7,0,23.1,9.9,23.9,22.4c0,0.3-0.2,0.6-0.5,0.6H47
c-0.4,0-0.7,0.5-0.5,0.9L50.5,33.1z M52.1,27c0.2,0,0.4,0.2,0.3,0.4l-1.1,2c-0.1,0.2-0.4,0.2-0.5,0l-1.1-2c-0.1-0.2,0-0.4,0.3-0.4
H52.1z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M17.1,30.1c0.2-0.2,0.4-0.5,0.6-0.7c0.2-0.2,0.5-0.4,0.7-0.6c0.4-0.3,0.8-0.6,1.3-0.8c0.5-0.3,0.9-0.5,1.4-0.8
c0.5-0.3,1-0.6,1.4-0.9c0.5-0.3,0.9-0.7,1.2-1.1c0.4-0.4,0.7-0.9,0.9-1.4c0.2-0.5,0.3-1.1,0.3-1.8c0-0.6-0.1-1.1-0.3-1.6
c-0.2-0.5-0.5-1-0.9-1.3c-0.4-0.4-0.9-0.7-1.5-0.9c-0.6-0.2-1.2-0.3-2-0.3c-0.7,0-1.4,0.1-2,0.4c-0.6,0.2-1.1,0.6-1.5,1
c-0.4,0.4-0.8,1-1,1.6c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.4,0.3,0.8,0.7,0.8c0.4,0,0.7-0.3,0.8-0.7c0-0.2,0.1-0.5,0.2-0.7
c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.9-0.3,1.4-0.3c0.5,0,0.9,0.1,1.3,0.2c0.4,0.1,0.7,0.3,1,0.6
c0.3,0.3,0.5,0.6,0.7,1c0.2,0.4,0.3,0.8,0.3,1.3c0,0.6-0.1,1.1-0.3,1.5c-0.2,0.4-0.5,0.8-0.9,1.1c-0.4,0.3-0.8,0.6-1.3,0.9
c-0.5,0.3-1,0.6-1.5,0.9c-0.5,0.3-1.1,0.7-1.7,1.1c-0.6,0.4-1.1,0.9-1.6,1.5c-0.5,0.6-0.8,1.1-0.9,1.7c-0.1,0.3-0.1,0.6-0.2,0.9
c-0.1,0.6,0.4,1,0.9,1h8.3c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6H17c-0.3,0-0.5-0.2-0.4-0.5c0-0.2,0.1-0.3,0.1-0.5
C16.8,30.6,16.9,30.3,17.1,30.1z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M34.2,33c0.4,0,0.7-0.3,0.7-0.7v-2.5c0-0.3,0.2-0.5,0.5-0.5h1c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6h-1
c-0.3,0-0.5-0.2-0.5-0.5v-8.9c0-0.3-0.3-0.6-0.6-0.6c-0.4,0-0.8,0.2-1,0.5l-6.3,9c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.3,0.3,0.6,0.6,0.6
H33c0.3,0,0.5,0.2,0.5,0.5v2.5C33.5,32.7,33.8,33,34.2,33z M28.5,28.1c-0.2,0-0.3-0.2-0.2-0.4l5-7.2c0.1-0.1,0.3-0.1,0.3,0.1v7
c0,0.3-0.2,0.5-0.5,0.5H28.5z"
        />
      </g>
    </g>
  </svg>
</template>
