<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 57.7 60.9"
    style="enable-background:new 0 0 57.7 60.9;"
    xml:space="preserve"
  >
    <g id="icon_17_">
      <g>
        <path
          :fill="color"
          d="M4.8,25.6c-0.3,0-0.5-0.2-0.5-0.5C3.7,19.8,3.9,15.2,5,11.4c0.1-0.3,0.4-0.4,0.7-0.4C6,11.1,6.1,11.4,6,11.7
C5,15.3,4.7,19.8,5.4,25C5.4,25.3,5.2,25.5,4.8,25.6C4.9,25.6,4.9,25.6,4.8,25.6z"
        />
        <path
          :fill="color"
          d="M4.8,26c-0.5,0-0.8-0.3-0.9-0.8c-0.7-5.4-0.4-10,0.7-13.9c0.1-0.5,0.7-0.8,1.1-0.6c0.5,0.1,0.8,0.7,0.6,1.1
c-1.1,3.6-1.3,8-0.7,13.1c0.1,0.5-0.3,1-0.8,1L4.8,26z M5.5,11.4c-0.1,0-0.1,0-0.2,0.1C4.3,15.2,4,19.8,4.7,25.1
c0,0.1,0.1,0.1,0.2,0.1v0.4l0-0.4C4.9,25.2,5,25.1,5,25c-0.6-5.2-0.4-9.8,0.7-13.4C5.7,11.5,5.7,11.4,5.5,11.4
C5.6,11.4,5.5,11.4,5.5,11.4z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M21.3,34.2C21.3,34.2,21.3,34.2,21.3,34.2c0-0.1,0-0.1,0-0.2c0.5-2.1,1.4-3.9,2.2-5.8c1.8-4,3.7-8.1,2.2-14.9
c-2.7-12.1-9.9-13-12.1-13c-1.2,0-2.3,0.2-3.4,0.7c-2.6,1-6,3.3-8.1,8.3C-1.5,18,0.4,31.5,7.7,49.5c2.7,6.7,5.4,11.1,9.6,11.1
c0.6,0,1.3-0.1,1.9-0.3c1.5-0.4,2.7-1.5,3.4-3c0.9-1.8,1.6-5-0.2-10.1C20.5,41.4,20.5,37.5,21.3,34.2z M21.7,56.7
c-0.6,1.3-1.6,2.1-2.8,2.5c-0.6,0.2-1.1,0.3-1.6,0.3c-3.6,0-6-4.2-8.6-10.4C1.9,32.3-0.1,19.6,2.7,11.1c2.3-4.5,10.1-6.6,16-4.2
c0.3,0.1,0.6,0,0.7-0.3c0.1-0.3,0-0.6-0.3-0.7c-5-2-11.2-1-14.9,1.8C6.1,4.5,8.7,2.8,10.7,2c0.9-0.4,1.9-0.6,3-0.6
c1.9,0,8.5,0.9,11,12.2c1.4,6.4-0.3,10.2-2.1,14.3c-0.7,1.6-1.5,3.2-2,5c-1.9-2.2-4.5-3.5-7.1-3.5c-3,0-5.8,1.5-7.9,4.2
c-0.2,0.2-0.1,0.6,0.1,0.8c0.2,0.2,0.6,0.1,0.8-0.1c1.9-2.5,4.3-3.8,6.9-3.8h0.1c2.6,0,5,1.4,6.8,3.7c-0.8,3.4-0.8,7.5,1.2,13.3
C23.1,52.2,22.5,55.1,21.7,56.7z"
        />
        <path
          :fill="color"
          d="M17.3,60.9c-4.4,0-7.1-4.5-9.9-11.3C0,31.6-1.9,18,1.8,9.2C4,4.1,7.5,1.8,10.2,0.7C11.3,0.2,12.5,0,13.7,0
c2.2,0,9.7,1,12.5,13.3c1.6,6.9-0.4,11.1-2.2,15.2c-0.9,1.9-1.7,3.6-2.2,5.6l0,0l-0.1,0.3c-0.9,3.7-0.5,7.7,1.2,12.6
c1.9,5.3,1.1,8.6,0.2,10.4c-0.8,1.6-2.1,2.8-3.7,3.2C18.7,60.8,18,60.9,17.3,60.9z M13.7,0.8c-1.1,0-2.2,0.2-3.2,0.6
C8,2.4,4.6,4.6,2.5,9.5c-3.6,8.6-1.7,22,5.6,39.8c2.7,6.5,5.3,10.9,9.2,10.9c0.6,0,1.2-0.1,1.8-0.3c1.3-0.4,2.5-1.4,3.2-2.9
c0.9-1.7,1.5-4.8-0.3-9.9c-1.7-4.9-2.1-9-1.2-12.8l0,0l0.1-0.5c0.5-2,1.3-3.8,2.2-5.7c1.8-4,3.7-8.1,2.2-14.8
C22.8,1.7,15.8,0.8,13.7,0.8z M17.3,59.9c-3.8,0-6.3-4.3-8.9-10.7C1.5,32.4-0.5,19.6,2.3,11c2.4-4.8,10.4-6.9,16.5-4.4
c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.2-0.1-0.2C14.2,4.4,8.1,5.2,4.4,8.1L3.8,7.6c2-3.4,4.8-5.1,6.7-5.9c1-0.4,2-0.6,3.1-0.6
c2,0,8.8,0.9,11.4,12.5c1.5,6.6-0.3,10.4-2.2,14.5c-0.7,1.5-1.5,3.1-2,4.9l-0.2,0.7l-0.4-0.5c-1.9-2.2-4.3-3.4-6.8-3.4
c-2.9,0-5.6,1.4-7.6,4.1c-0.1,0.1,0,0.2,0,0.2c0.1,0,0.2,0,0.2,0c1.9-2.6,4.5-4,7.2-4h0.1c2.7,0,5.3,1.4,7.1,3.9l0.1,0.1l0,0.2
c-0.9,3.9-0.5,8.1,1.2,13.1c1.7,4.9,1.1,8,0.3,9.6c-0.7,1.3-1.7,2.3-3,2.7C18.5,59.8,17.9,59.9,17.3,59.9z M13.3,6.3
c-4.4,0-8.7,1.9-10.3,5c-2.8,8.3-0.7,21,6.1,37.6c2.5,6.1,4.9,10.2,8.2,10.2c0.5,0,1-0.1,1.5-0.2c1.1-0.3,2-1.2,2.6-2.3l0,0
c0.8-1.5,1.3-4.3-0.3-9c-1.8-5.1-2.2-9.3-1.3-13.3c-1.7-2.2-4-3.4-6.4-3.4h-0.1c-2.5,0-4.8,1.3-6.6,3.7c-0.1,0.2-0.4,0.3-0.6,0.4
c-0.2,0-0.5,0-0.7-0.2c-0.4-0.3-0.5-0.9-0.2-1.3c2.2-2.9,5.1-4.4,8.2-4.4c2.6,0,5,1.1,7,3.2c0.5-1.6,1.2-3.1,1.8-4.4
c1.8-4,3.5-7.7,2.1-14c-2.5-11-8.8-11.9-10.7-11.9c-1,0-1.9,0.2-2.8,0.5C9.3,3,7.3,4.2,5.5,6.4c3.9-2.2,9.4-2.6,13.6-0.8
c0.5,0.2,0.7,0.7,0.5,1.2c-0.2,0.5-0.7,0.7-1.2,0.5C16.8,6.6,15,6.3,13.3,6.3z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M55.5,9.3c-2.1-5-5.6-7.3-8.1-8.3c-1.1-0.4-2.2-0.7-3.4-0.7c-2.2,0-9.4,0.9-12.1,13c-1.5,6.8,0.4,10.9,2.2,14.9
c0.9,1.9,1.7,3.7,2.2,5.8c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.8,3.3,0.8,7.2-1.2,12.8c-1.8,5.2-1.1,8.4-0.2,10.2c0.8,1.5,2,2.6,3.4,3
c0.7,0.2,1.3,0.3,1.9,0.3c4.2,0,6.9-4.4,9.6-11.1C57.3,31.5,59.1,18,55.5,9.3z M48.9,49.1c-2.6,6.3-5,10.4-8.6,10.4
c-0.5,0-1.1-0.1-1.6-0.3c-1.2-0.4-2.2-1.2-2.8-2.5c-0.8-1.6-1.4-4.5,0.3-9.3c2-5.8,2-9.9,1.2-13.3c1.8-2.3,4.2-3.7,6.8-3.7
c2.6,0,5.1,1.3,7,3.8c0.2,0.2,0.5,0.3,0.8,0.1c0.2-0.2,0.3-0.5,0.1-0.8c-2.1-2.8-4.9-4.3-7.9-4.2c-2.7,0-5.2,1.3-7.1,3.5
c-0.5-1.8-1.3-3.4-2-5c-1.9-4-3.6-7.8-2.1-14.3C35.5,2.3,42,1.5,44,1.5c1,0,2,0.2,3,0.6c2,0.8,4.6,2.5,6.5,5.7
c-3.7-2.9-9.9-3.8-14.9-1.8c-0.3,0.1-0.4,0.4-0.3,0.7C38.4,6.9,38.8,7,39,6.9c5.9-2.4,13.6-0.3,16,4.2
C57.8,19.6,55.8,32.3,48.9,49.1z"
        />
        <path
          :fill="color"
          d="M40.3,60.9c-0.7,0-1.3-0.1-2-0.3c-1.5-0.5-2.9-1.6-3.7-3.2c-0.9-1.8-1.6-5.1,0.2-10.4c1.7-4.8,2.1-8.8,1.2-12.6l-0.1-0.3
l0,0c-0.5-2-1.3-3.7-2.2-5.6c-1.9-4.1-3.8-8.3-2.3-15.2C34.3,1,41.8,0,44,0c1.2,0,2.4,0.2,3.5,0.7c2.6,1.1,6.2,3.4,8.3,8.5
c3.7,8.8,1.8,22.4-5.6,40.4C47.5,56.4,44.7,60.9,40.3,60.9z M36.8,34.3c0.9,3.8,0.5,8-1.2,12.9c-1.8,5.1-1.1,8.2-0.2,9.9
c0.7,1.4,1.9,2.4,3.2,2.8c0.6,0.2,1.2,0.3,1.8,0.3c4,0,6.6-4.3,9.2-10.9c7.3-17.8,9.2-31.2,5.6-39.8l0,0c-2-4.9-5.4-7.1-7.9-8.1
c-1-0.4-2.1-0.6-3.2-0.6c-2.1,0-9.1,0.9-11.7,12.7c-1.5,6.7,0.4,10.8,2.2,14.7c0.9,1.9,1.7,3.7,2.2,5.7h0L36.8,34.3L36.8,34.3z
 M40.3,59.9c-0.6,0-1.1-0.1-1.7-0.3c-1.3-0.4-2.3-1.3-3-2.7c-0.8-1.6-1.5-4.7,0.3-9.6c1.7-5,2.1-9.1,1.2-13.1l0-0.2l0.1-0.1
c1.8-2.4,4.4-3.8,7.1-3.9c2.8,0,5.3,1.4,7.3,4c0,0.1,0.2,0.1,0.2,0c0.1-0.1,0.1-0.2,0-0.2c-2-2.7-4.7-4.1-7.6-4.1
c-2.6,0-5,1.2-6.8,3.4l-0.4,0.5L36.7,33c-0.5-1.8-1.3-3.5-2-4.9c-1.9-4.1-3.6-7.9-2.2-14.5C35.2,2,42,1.1,44,1.1
c1.1,0,2.1,0.2,3.1,0.6c1.9,0.8,4.7,2.5,6.7,5.9l-0.6,0.5c-3.8-2.9-9.9-3.7-14.5-1.8c-0.1,0-0.1,0.1-0.1,0.2
c0,0.1,0.1,0.1,0.2,0.1c6-2.4,14-0.3,16.5,4.4c2.9,8.6,0.8,21.4-6.1,38.2l0,0C46.7,55.6,44.1,59.9,40.3,59.9z M37.8,34.2
c0.9,4,0.5,8.2-1.3,13.3c-1.6,4.7-1.1,7.5-0.3,9c0.6,1.2,1.5,2,2.6,2.3c4.2,1.3,6.9-3,9.7-9.9c6.8-16.6,8.9-29.3,6.1-37.7
c-2.2-4.3-9.8-6.3-15.5-4c-0.5,0.2-1,0-1.2-0.5c-0.2-0.5,0-1,0.5-1.2c4.3-1.7,9.7-1.3,13.6,0.8c-1.7-2.2-3.8-3.4-5.3-4
C45.9,2,45,1.8,44,1.8c-1.9,0-8.2,0.9-10.7,11.9c-1.4,6.3,0.3,10.1,2.1,14c0.6,1.3,1.3,2.8,1.8,4.4c1.9-2,4.4-3.1,7-3.2
c3.1,0,6,1.5,8.2,4.4c0.3,0.4,0.2,1-0.2,1.3c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0-0.5-0.2-0.6-0.4c-1.8-2.4-4.1-3.7-6.6-3.7
c0,0-0.1,0-0.1,0C41.8,30.8,39.5,32.1,37.8,34.2z"
        />
      </g>
    </g>
  </svg>
</template>
