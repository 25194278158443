<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 53.8 54.8"
    style="enable-background:new 0 0 53.8 54.8;"
    xml:space="preserve"
  >
    <g id="icon_14_">
      <g>
        <path
          :fill="color"
          d="M44.8,54.6H31.9c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h12.2l-5.7-22.7c0-0.2,0-0.3,0.1-0.4
c0.1-0.1,0.3-0.2,0.4-0.2h13.3L42.8,6.6c-1.3-3.1-4.5-5.2-8.1-5.3l-6.2,11.6c-0.1,0.3-0.5,0.3-0.7,0.2c-0.3-0.1-0.3-0.5-0.2-0.7
l6.3-11.8c0.1-0.2,0.3-0.3,0.5-0.3c4.2,0,8,2.4,9.4,6l9.8,24.2c0.1,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.3,0.2-0.4,0.2H39.7l5.7,22.7
c0,0.2,0,0.3-0.1,0.4C45.1,54.5,45,54.6,44.8,54.6z"
        />
        <path
          :fill="color"
          d="M44.8,54.8H31.9c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h11.9l-5.6-22.4c-0.1-0.2,0-0.5,0.1-0.7c0.1-0.2,0.4-0.3,0.6-0.3
h12.9L42.5,6.7c-1.2-3-4.3-5-7.8-5.2L28.7,13c-0.2,0.4-0.7,0.5-1,0.3c-0.4-0.2-0.5-0.7-0.3-1l6.3-11.8C33.8,0.2,34,0,34.3,0
c4.3,0,8.2,2.5,9.6,6.1l9.8,24.2c0.1,0.2,0.1,0.5-0.1,0.7c-0.1,0.2-0.4,0.3-0.6,0.3H40l5.6,22.4c0.1,0.2,0,0.5-0.1,0.7
C45.3,54.7,45,54.8,44.8,54.8z M31.9,53.8c-0.1,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3h12.9c0.1,0,0.2,0,0.2-0.1
c0.1-0.1,0.1-0.2,0-0.2l-5.7-23H53c0.1,0,0.2,0,0.2-0.1c0-0.1,0.1-0.2,0-0.3L43.5,6.3c-1.4-3.5-5.1-5.8-9.2-5.8
c-0.1,0-0.2,0.1-0.2,0.1l-6.3,11.8c-0.1,0.1,0,0.3,0.1,0.4c0.1,0.1,0.3,0,0.4-0.1L34.5,1l0.2,0c3.7,0.1,7.1,2.3,8.4,5.5l9.6,23.9
H39c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.2,0,0.2l5.7,23.1H31.9z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M29,54.6H9.5c-0.2,0-0.3-0.1-0.4-0.2C9,54.3,8.9,54.1,9,53.9l5.8-23.3l-1.4-9c0-0.3,0.1-0.6,0.4-0.6
c0.3,0,0.6,0.1,0.6,0.4l1.4,9.1c0,0.1,0,0.1,0,0.2l-5.7,22.7h18.5l4.3-22.8L19.1,1.3c-3.6,0.1-6.8,2.2-8.1,5.3L1.5,30.2H12
c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5H0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5L10,6.2c1.4-3.6,5.2-6,9.4-6
c0.2,0,0.4,0.1,0.5,0.3l14,29.9c0,0.1,0.1,0.2,0,0.3l-4.4,23.4C29.5,54.4,29.3,54.6,29,54.6z"
        />
        <path
          :fill="color"
          d="M29,54.8H9.5c-0.2,0-0.5-0.1-0.6-0.3c-0.1-0.2-0.2-0.4-0.1-0.7l5.8-23.2l-1.4-8.9c-0.1-0.4,0.2-0.8,0.6-0.9
c0.4-0.1,0.8,0.2,0.9,0.6l1.4,9.1c0,0.1,0,0.2,0,0.3l-5.6,22.4h17.9l4.2-22.5L19,1.6c-3.4,0.2-6.5,2.2-7.7,5.2L1.9,29.9H12
c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8H0.8c-0.3,0-0.5-0.1-0.6-0.3C0,30.9,0,30.6,0.1,30.4L9.8,6.1C11.3,2.5,15.2,0,19.4,0
c0.3,0,0.6,0.2,0.7,0.4l14,29.9c0.1,0.1,0.1,0.3,0.1,0.5l-4.4,23.4C29.7,54.6,29.4,54.8,29,54.8z M13.8,21.4
C13.8,21.4,13.8,21.4,13.8,21.4c-0.1,0-0.2,0.1-0.2,0.1c0,0.1-0.1,0.1,0,0.2l1.4,9l0,0L9.2,54c0,0.1,0,0.2,0,0.2
c0.1,0.1,0.1,0.1,0.2,0.1H29c0.1,0,0.2-0.1,0.3-0.2l4.4-23.4c0-0.1,0-0.1,0-0.2l-14-29.9c0-0.1-0.1-0.2-0.2-0.2
c-4.1,0-7.8,2.3-9.2,5.8L0.5,30.6c0,0.1,0,0.2,0,0.3C0.6,30.9,0.7,31,0.8,31H12c0.1,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3H1.2
l9.6-23.9c1.3-3.2,4.6-5.4,8.3-5.5l0.2,0l13.9,29.7l0,0.1l-4.3,23h-19l5.7-23.1c0,0,0-0.1,0-0.1l-1.4-9.1
C14.1,21.4,14,21.4,13.8,21.4z"
        />
      </g>
    </g>
  </svg>
</template>
