<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60 41"
    style="enable-background:new 0 0 60 41;"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          :fill="color"
          d="M1,39c-0.6,0-1,0.4-1,1s0.4,1,1,1h58c0.6,0,1-0.4,1-1s-0.4-1-1-1H1z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M58.8,37c0.6,0,0.9-0.5,0.9-1.1C57.7,21.3,45.2,10,30,10S2.3,21.3,0.3,35.9C0.2,36.5,0.6,37,1.2,37H58.8z M30,12
c13.6,0,24.9,9.7,27.5,22.5c0,0.2-0.2,0.5-0.4,0.5H3c-0.3,0-0.5-0.2-0.4-0.5C5.1,21.7,16.4,12,30,12z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M35,5c0-2.8-2.2-5-5-5c-2.8,0-5,2.2-5,5s2.2,5,5,5C32.8,10,35,7.8,35,5z M30,8c-1.7,0-3-1.3-3-3s1.3-3,3-3
c1.7,0,3,1.3,3,3S31.7,8,30,8z"
        />
      </g>
    </g>
  </svg>
</template>
