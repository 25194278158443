<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 52 52"
    style="enable-background:new 0 0 52 52;"
    xml:space="preserve"
  >
    <path
      :fill="color"
      d="M14,42c2.2,0,4-1.8,4-4c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4C10,40.2,11.8,42,14,42z M14,36c1.1,0,2,0.9,2,2
	c0,1.1-0.9,2-2,2s-2-0.9-2-2C12,36.9,12.9,36,14,36z M38,42c2.2,0,4-1.8,4-4c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4
	C34,40.2,35.8,42,38,42z M38,36c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2s-2-0.9-2-2C36,36.9,36.9,36,38,36z M47,6h-1.1
	c-0.5-3.4-3.4-6-6.9-6H13C9.5,0,6.6,2.6,6.1,6H5c-2.8,0-5,2.2-5,5v6c0,1.7,1.3,3,3,3h3v23c0,1.3,0.8,2.4,2,2.8V50c0,1.1,0.9,2,2,2h4
	c1.1,0,2-0.9,2-2v-4h20v4c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-4.2c1.2-0.4,2-1.5,2-2.8V20h3c1.7,0,3-1.3,3-3v-6C52,8.2,49.8,6,47,6z
	 M6,18H3c-0.6,0-1-0.4-1-1v-6c0-1.7,1.3-3,3-3h1V18z M13,2h26c2.4,0,4.4,1.7,4.9,4H8.1C8.6,3.7,10.6,2,13,2z M14,50h-4v-4h4V50z
	 M42,50h-4v-4h4V50z M44,43c0,0.6-0.4,1-1,1H9c-0.6,0-1-0.4-1-1V30h36V43z M44,28H8V8h36V28z M50,17c0,0.6-0.4,1-1,1h-3V8h1
	c1.7,0,3,1.3,3,3V17z"
    />
  </svg>
</template>
