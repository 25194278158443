<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len -->
  <!-- Generator: Adobe Illustrator 21.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 82.1 82.7"
    style="enable-background:new 0 0 82.1 82.7;"
    xml:space="preserve"
  >
    <g>
      <path
        :fill="color"
        d="M71.7,58.9H16.6c-4.3,0-7.8-3.5-7.8-7.8V39.7h2V51c0,3.2,2.6,5.8,5.8,5.8h55.1c3.2,0,5.8-2.6,5.8-5.8v-6.2h2V51
C79.5,55.4,76,58.9,71.7,58.9z"
      />
      <path
        :fill="color"
        d="M80.2,40.7H25.3v-2h54.8v-6.2H8.3v6.2h8.5v2H8.1c-1,0-1.9-0.8-1.9-1.9v-6.5c0-1,0.8-1.9,1.9-1.9h72.1c1,0,1.9,0.8,1.9,1.9
v6.5C82.1,39.9,81.2,40.7,80.2,40.7z"
      />
      <g>
        <rect x="16.3" y="58.7" width="2" height="4" />
        <rect x="69.1" y="58.7" width="2" height="4" />
      </g>
      <path
        :fill="color"
        d="M2,33.8l-2-0.4c1.2-5.7,6.2-9.8,12.1-9.8c1.5,0,2.9,0.3,4.3,0.8c2.8-2.1,6.4-2.9,9.9-2.1c1.1-1.4,2.8-2.2,4.7-2.2
c1.9,0,3.7,0.9,4.8,2.4c0.9-0.5,1.9-0.8,3-0.8c1.8,0,3.5,0.8,4.6,2.2c0.9-0.6,2-0.9,3.1-0.9c2,0,3.9,1.1,5,2.7
c0.6-0.1,1.3-0.2,1.9-0.2v2c-0.7,0-1.5,0.1-2.2,0.2l-0.8,0.2l-0.3-0.7c-0.7-1.4-2.1-2.3-3.6-2.3c-1,0-2,0.4-2.7,1l-0.9,0.9
l-0.6-1.1c-0.7-1.3-2.1-2.1-3.5-2.1c-1,0-1.9,0.3-2.6,1l-1,0.8l-0.6-1.2C33.9,22.9,32.5,22,31,22c-1.4,0-2.7,0.7-3.4,1.9l-0.4,0.6
l-0.7-0.2c-3.3-0.9-6.7-0.1-9.3,1.9l-0.5,0.4l-0.6-0.2c-1.3-0.6-2.7-0.8-4.1-0.8C7.2,25.5,2.9,29,2,33.8z"
      />
      <rect x="74.7" y="25" width="2" height="2.4" />
      <rect x="0" y="41.4" width="2" height="2.4" />
      <rect x="68.8" y="17.7" width="2" height="2.4" />
      <rect x="60" y="23.2" width="2" height="2.4" />
      <rect x="3" y="53.9" width="2" height="2.4" />
    </g>
  </svg>
</template>
