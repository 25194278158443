<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60 48"
    style="enable-background:new 0 0 60 48;"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          :fill="color"
          d="M1,0C0.4,0,0,0.4,0,1v46c0,0.6,0.4,1,1,1h58c0.6,0,1-0.4,1-1V1c0-0.6-0.4-1-1-1H1z M4,45.5C4,45.8,3.8,46,3.5,46h-1
C2.2,46,2,45.8,2,45.5v-43C2,2.2,2.2,2,2.5,2h1C3.8,2,4,2.2,4,2.5V45.5z M20,45.5c0,0.3-0.2,0.5-0.5,0.5h-13C6.2,46,6,45.8,6,45.5
v-43C6,2.2,6.2,2,6.5,2h13C19.8,2,20,2.2,20,2.5V45.5z M58,45.5c0,0.3-0.2,0.5-0.5,0.5h-35c-0.3,0-0.5-0.2-0.5-0.5v-43
C22,2.2,22.2,2,22.5,2h35C57.8,2,58,2.2,58,2.5V45.5z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M56,5c0-0.6-0.4-1-1-1H25c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h30c0.6,0,1-0.4,1-1V5z M54,9.5c0,0.3-0.2,0.5-0.5,0.5h-27
C26.2,10,26,9.8,26,9.5v-3C26,6.2,26.2,6,26.5,6h27C53.8,6,54,6.2,54,6.5V9.5z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M30,17c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1.5c-0.3,0-0.5-0.2-0.5-0.5V17
z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M40,17c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1.5c-0.3,0-0.5-0.2-0.5-0.5V17
z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M50,17c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1.5c-0.3,0-0.5-0.2-0.5-0.5V17
z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M30,27c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1.5c-0.3,0-0.5-0.2-0.5-0.5V27
z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M40,27c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1.5c-0.3,0-0.5-0.2-0.5-0.5V27
z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M50,27c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1.5c-0.3,0-0.5-0.2-0.5-0.5V27
z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M30,37c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1.5c-0.3,0-0.5-0.2-0.5-0.5V37
z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M40,37c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1.5c-0.3,0-0.5-0.2-0.5-0.5V37
z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M50,37c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1.5c-0.3,0-0.5-0.2-0.5-0.5V37
z"
        />
      </g>
    </g>
  </svg>
</template>
